import { Observable, catchError, map, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const API_KEY = 'AIzaSyBgDZeER7nA3wDzoaRR6z_asLkgZQHOKH0';
@Injectable()
export class GoogleMapsService {
  public apiLoaded$: Observable<boolean>;

  constructor(private httpClient: HttpClient) {
    this.apiLoaded$ = httpClient
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?key=' + API_KEY,
        'callback',
      )
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  calculateDistance(
    coord1: google.maps.LatLngLiteral,
    coord2: google.maps.LatLngLiteral,
  ): number {
    const coordenada1 = new google.maps.LatLng(coord1.lat, coord1.lng);
    const coordenada2 = new google.maps.LatLng(coord2.lat, coord2.lng);

    return Math.round(
      google.maps.geometry.spherical.computeDistanceBetween(
        coordenada1,
        coordenada2,
      ) / 1000,
    );
  }

  getCityName(latitude: number, longitude: number) {
    return this.httpClient.get<any>(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`,
      { responseType: 'json' },
    );
  }
}
